<template>
  <div>
    <v-tabs v-model="tab" color="secondary">
      <v-tabs-slider color="primary" />
      <v-tab v-for="{ id, name, to } in tabs" :key="id" class="text-capitalize" :to="to" v-text="name" />
    </v-tabs>
    <v-divider />
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Activity',
  data() {
    return {
      isDataLoaded: false,
      tab: null,
    }
  },
  computed: {
    isStreaming() {
      return this.$store.state.activity.dbData?.type === 'streaming'
    },
    tabs() {
      return [
        {
          id: 'content',
          name: this.$t('common.content'),
          to: { name: 'activity-edit' },
        },
        {
          id: 'comments',
          name: this.$t('common.comments'),
          to: { name: 'activity-comments' },
        },
        ...(this.isStreaming && this.$store.state.streaming.dbData?.status === 'finished' ? [{
          id: 'streaming-stats',
          name: this.$t('streamingStats.title'),
          to: { name: 'activity-streaming-stats' },
        }] : []),
      ]
    },
  },
}
</script>
